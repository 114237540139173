<template>
  <div class="homePagePetro">
    <header
      class="header headroom headroom--not-bottom header-pin headroom--top"
    >
      <div class="header__fixheight"></div>
      <div class="header__content">
        <div class="header__inner">
          <div class="zone logo header__logo">
            <router-link to="/home">
              <img alt="PLX" src="@/assets/petrolimex.png" />
            </router-link>
            <p>Nền tảng giao dịch</p>
          </div>
          <router-link to="/home"> Giao dịch </router-link>
        </div>
      </div>
    </header>
    <div class="page-content">
      <div class="zone content">
        <div class="portlet slides-anh">
          <div class="content">
            <section class="section hero">
              <div class="hero__bg">
                <carousel
                  :per-page="1"
                  :navigate-to="0"
                  paginationEnabled
                  loop
                  autoplay
                  class="caruousel_container"
                >
                  <slide class="caruousel">
                    <div class="item item1"></div>
                  </slide>
                  <slide class="caruousel">
                    <div class="item item2">
                      <div class="item__content">
                        <div class="container">
                          <div class="row">
                            <div class="col-xl-9">
                              <h2 class="item__title">
                                <router-link to="/home"
                                  >Thanh toán thông minh - Lợi ích đồng
                                  hành</router-link
                                >
                              </h2>
                              <p class="item__text">
                                Tận hưởng các tiện ích cùng nhiều quyền lợi từ
                                chương trình chăm sóc khách hàng thân thiết
                                Petrolimex
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </slide>
                  <slide class="caruousel">
                    <div class="item item3">
                      <div class="item__content">
                        <div class="container">
                          <div class="row">
                            <div class="col-xl-9">
                              <h2 class="item__title">
                                <router-link to="/home"
                                  >Cam kết mang lại giá trị tốt nhất cho khách
                                  hàng, đối tác và cổ đông</router-link
                                >
                              </h2>
                              <p class="item__text">
                                Với hệ thống quản trị minh bạch, hiện đại theo
                                các chuẩn mực quốc tế
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </slide>
                  <slide class="caruousel">
                    <div class="item item4"></div>
                  </slide>
                </carousel>
              </div>
            </section>
          </div>
        </div>
        <section class="section about-detail">
          <div class="container">
            <div class="row">
              <div class="col-md-5 col-xl-5 col-xxl-4">
                <h2
                  class="about-detail__title title-fz-40"
                  style="line-height: 52px"
                >
                  Petrolimex giữ vững vị trí chủ lực trên thị trường xăng dầu
                  nội địa
                </h2>
              </div>
              <div class="col-md-7 col-xl-7 offset-xxl-1">
                <div class="about-detail__entry entry-detail">
                  <p>
                    Tập đoàn Xăng dầu Việt Nam (tên viết tắt là Petrolimex) hiện
                    nay được hình thành từ việc cổ phần hóa và cấu trúc lại Tổng
                    công ty Xăng dầu Việt Nam. Lĩnh vực kinh doanh chính của
                    Petrolimex là xuất nhập khẩu và kinh doanh xăng dầu, lọc -
                    hóa dầu, đầu tư vốn vào các doanh nghiệp khác để kinh doanh
                    các ngành nghề mà Petrolimex đang kinh doanh và các ngành
                    nghề kinh doanh khác theo quy định của pháp luật.
                  </p>
                  <hr />
                  <p>
                    <strong
                      >Petrolimex luôn bảo đảm đầy đủ và kịp thời các chủng loại
                      xăng dầu</strong
                    >
                  </p>
                  <p>
                    Petrolimex luôn bảo đảm đầy đủ và kịp thời các chủng loại
                    xăng dầu phục vụ sự nghiệp phát triển kinh tế - xã hội của
                    đất nước, bảo đảm an ninh quốc phòng và nhu cầu tiêu dùng
                    của nhân dân
                  </p>
                  <hr />
                  <p>
                    <strong
                      >Petrolimex luôn tiên phong áp dụng công nghệ mới</strong
                    >
                  </p>
                  <p>
                    Tiên phong trong việc áp dụng công nghệ mới vào hoạt động
                    sản xuất kinh doanh, tăng năng suất lao động, phục vụ khách
                    hàng ngày một tốt hơn đồng thời đảm bảo công tác an toàn, an
                    ninh năng lượng, quản lý và kinh doanh có hiệu quả
                  </p>
                  <hr />
                  <p>
                    <strong
                      >Petrolimex phát triển kinh doanh dịch vụ gia tăng ngoài
                      xăng dầu</strong
                    >
                  </p>
                  <p>
                    Tập trung ưu tiên nghiên cứu và hoàn thiện các mô hình,
                    phương án kinh doanh cũng như lựa chọn đối tác phù hợp để
                    phát triển dịch vụ gia tặng tại hệ thống cửa hàng xăng dầu
                    Petrolimex, tận dụng tối đa lợi thế chuỗi bán lẻ, làm gia
                    tăng hiệu quả kinh doanh và lợi nhuận.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="portlet thong-diep-lanh-dao" id="thongdieplanhdao">
          <div>
            <section class="section slogan-ceo">
              <div class="slogan-ceo__inner">
                <div class="container">
                  <div class="row">
                    <div class="col-md-9 col-xl-9">
                      <div class="slogan-ceo__blockquote">
                        <span class="f-icon"
                          ><img
                            src="https://portals.petrolimex.com.vn/_themes/sunrise/img/slogan-ceo-icon.svg"
                            alt=""
                        /></span>
                        <h3 class="f-title">
                          Đứng từ góc độ phát triển bền vững, Petrolimex tự hào
                          là một doanh nghiệp luôn có trách nhiệm với xã hội,
                          luôn tôn trọng và hài hòa lợi ích của các bên liên
                          quan, bảo vệ môi trường và đóng góp lớn cho ngân sách
                          Nhà nước. Petrolimex luôn là doanh nghiệp tiên phong
                          trong chuyển đổi và kinh doanh các sản phẩm năng lượng
                          sạch, thân thiện môi trường.
                        </h3>
                        <p class="f-info">
                          Ông Phạm Văn Thanh <br />Chủ tịch Hội đồng quản trị
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="bg"
                style="
                  background-image: url(https://files.petrolimex.com.vn/files/6783dc1271ff449e95b74a9520964169/image=jpeg/837f925785bb46b2b42fc66f15fc52da/%E1%BA%A3nh%20ch%E1%BB%A7%20tich%20pc%2022-4.jpg);
                "
              >
                <img
                  src="https://files.petrolimex.com.vn/files/6783dc1271ff449e95b74a9520964169/image=jpeg/f29714d65ef34f7ab50e11b24247b161/%E1%BA%A3nh%20ch%E1%BB%A7%20t%E1%BB%8Bch%20mb%2022-4.jpg"
                  alt=""
                  class="d-md-none"
                />
              </div>
            </section>
          </div>
        </div>
        <div class="portlet gia-tri-cot-loi" id="giatricotloi">
          <div>
            <section class="section sec-coreValue">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-md-7 col-lg-6 col-xl-5">
                    <div class="title">
                      <h2 class="title__title title-fz-40">Giá trị cốt lõi</h2>
                      <p class="title__text">
                        Được kết tinh từ công sức, tri thức của các thế hệ
                        CBCNV-NLĐ Petrolimex trong suốt 68 năm qua cùng định
                        hướng chiến lược phát triển mang hơi thở của thời
                        đại.<br />Từ giá trị cốt lõi, thương hiệu Petrolimex sẽ
                        luôn giữ vững được bản sắc riêng của mình, các hoạt động
                        sản xuất – kinh doanh nhất quán với định hướng phát
                        triển bền vững vì lợi ích của cổ đông, đối tác, bạn hàng
                        và khách hàng.
                      </p>
                    </div>
                    <div class="f-list">
                      <ul>
                        <li>
                          <h4>Đa dạng</h4>
                          <p>Đánh giá cao sự khác biệt và tính phong phú</p>
                        </li>
                        <li>
                          <h4>Di sản</h4>
                          <p>Tự hào là Việt Nam</p>
                        </li>
                        <li>
                          <h4>Nhân bản</h4>
                          <p>Đặt con người làm trung tâm trong mọi hành động</p>
                        </li>
                        <li>
                          <h4>Phát triển</h4>
                          <p>Không ngừng vươn lên và đổi mới để hoàn thiện</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-5 offset-lg-1 col-xl-6">
                    <div class="f-img wow effect" style="visibility: visible">
                      <span class="item item-1"
                        ><img
                          src="https://portals.petrolimex.com.vn/_themes/sunrise/img/coreValue-dadang.png"
                          alt="" /></span
                      ><span class="item item-2"
                        ><img
                          src="https://portals.petrolimex.com.vn/_themes/sunrise/img/coreValue-disan.png"
                          alt="" /></span
                      ><span class="item item-3"
                        ><img
                          src="https://portals.petrolimex.com.vn/_themes/sunrise/img/coreValue-nhanban.png"
                          alt="" /></span
                      ><span class="item item-4"
                        ><img
                          src="https://portals.petrolimex.com.vn/_themes/sunrise/img/coreValue-phattrien.png"
                          alt="" /></span
                      ><span class="item item-center"
                        ><img
                          src="https://portals.petrolimex.com.vn/_themes/sunrise/img/coreValue-logo-center.png"
                          alt=""
                      /></span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div class="portlet hinh-thanh-phat-trien" id="hinhthanhvaphattrien">
          <div>
            <section
              class="section sec-history"
              style="
                background-image: url(https://portals.petrolimex.com.vn/_themes/sunrise/img/peroBrand-bg.jpg);
              "
            >
              <div class="container">
                <div class="row">
                  <div class="col-md-5 col-lg-4">
                    <div class="title">
                      <h2 class="title__title title-fz-40">
                        Hình thành <br />và phát triển
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div class="f-history">
                <img
                  class="pc"
                  src="https://portals.petrolimex.com.vn/_themes/sunrise/img/history-img-pc.png"
                  alt=""
                /><img
                  class="mb"
                  src="https://portals.petrolimex.com.vn/_themes/sunrise/img/history-img-mb.png"
                  alt=""
                />
              </div>
            </section>
          </div>
        </div>
        <div class="portlet mo-hinh-quan-tri" id="mohinhquantri">
          <div>
            <section class="section sec-modalAdmin">
              <div class="container">
                <div class="row">
                  <div class="col-md-6 col-xl-5">
                    <div class="title-margin">
                      <h2 class="title__title title-fz-40">Mô hình quản trị</h2>
                    </div>
                  </div>
                </div>
                <div class="f-img">
                  <img
                    src="https://files.petrolimex.com.vn/files/6783dc1271ff449e95b74a9520964169/image=png/251451bb5d444a9d9ac171c1864ce63c/Mohinh-quantri-052024-xam.png"
                    class="img-fullwidth"
                    alt=""
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <footer
      class="footer"
      style="
        background-image: url(https://portals.petrolimex.com.vn/_themes/sunrise/img/footer-bg.jpg);
      "
    >
      <div class="container">
        <div class="footer__content">
          <div class="row align-items-center">
            <div class="zone widgets col-md-7 col-lg-7 col-xl-7">
              <div class="portlet bottom-menu">
                <div class="content">
                  <div class="footer__list">
                    <ul>
                      <li><routerLink to="/home">Giới thiệu</routerLink></li>
                      <li>
                        <routerLink to="/home">Lĩnh vực hoạt động</routerLink>
                      </li>
                      <li><routerLink to="/home">Nhà đầu tư</routerLink></li>
                      <li><routerLink to="/home">Tin tức</routerLink></li>
                      <li><routerLink to="/home">Liên hệ</routerLink></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer__footer">
          <div class="row">
            <div class="zone notices col-md-8 col-xl-6 footer__copyright">
              <div class="portlet thong-tin-lien-he">
                <div>
                  <p>
                    <span style="float: right; font-size: 120%"
                      ><a
                        title="Nội dung cập nhật mới nhất"
                        style="margin: 0 15px 0 5px"
                        ><i class="fa fa-rss-square"></i></a
                      ><a title="Trang Facebook chính thức của Petrolimex"
                        ><i class="fa fa-facebook-square"></i></a></span
                    >Trang TTĐTTH Tập đoàn Xăng dầu Việt Nam - Petrolimex (PLX)
                  </p>
                  <p>
                    Giấy phép số
                    <a href="/giay-phep.html"
                      ><b><i>1900/GP-TTĐT</i></b></a
                    >
                    do Sở TT&amp;TT Hà Nội cấp ngày 8/7/2020
                  </p>
                  <p>
                    Chịu trách nhiệm nội dung: Ông Nguyễn Quang Dũng - Phó Tổng
                    Giám đốc
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    Carousel,
    Slide,
  },
};
</script>
<style>
.VueCarousel-pagination {
  display: none;
}
.VueCarousel-dot {
  display: none !important;
}
.VueCarousel-wrapper {
  height: 100%;
}
.VueCarousel-inner {
  height: 100% !important;
}
</style>
<style lang="scss" scoped>
::selection {
  background: #0a54a8;
  color: #fff;
}
img::selection {
  background: transparent;
}
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
.btn.secondary {
  background: linear-gradient(to right, #fcba4e 0%, #f87926 50%, #fcba4e 100%);
  background-size: auto;
  background-size: 200% 100%;
  color: #fff;
  text-decoration: none;
}
.btn {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  height: 44px;
  line-height: 44px;
  background-color: #0a54a8;
  color: #fff;
  padding: 0 20px;
}
.homePagePetro {
  background: #fff;
  height: 100%;
}
.header {
  position: relative;
  z-index: 50;
}
.header__content {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  transition: all 0.3s ease;
}
.header.headroom--not-top:not(.header-border) .header__content {
  border-bottom: 1px solid #e6e6e6;
}
.header.header-unpin .header__content {
  transform: translateY(-100%);
}
.header__fixheight {
  height: 80px;
}
/*.header__content {
  filter: grayscale(100%);
}*/
.header__inner {
  display: flex;
  align-items: center;
  font-size: 15px;
  padding: 0 20px;
  height: 80px;
  justify-content: space-between;
}
.header__logo {
  display: flex;
  align-items: center;
  gap: 20px;
}
.header__inner a,
.header__inner p {
  margin: 0;
  white-space: nowrap;
  display: block;
  font-weight: 600;
  font-size: 22px;
  color: #261d35;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}
.header__logo a {
  display: block;
  max-width: 180px;
  flex-shrink: 0;
}
a {
  color: #666666;
  text-decoration: none;
  outline: 0;
  transition: all 0.3s ease;
}
.page-content {
  position: relative;
}
.page-wrapper,
.page-content {
  overflow: hidden;
}
.zone > .portlet > .content {
  float: none;
  clear: both;
  margin: 0;
  padding: 0;
}
.hero {
  min-height: calc(100vh - 60px);
  padding: 0;
}
.section,
section {
  position: relative;
  z-index: 2;
  padding-top: 50px;
  padding-bottom: 50px;
}
.hero__bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.caruousel_container {
  height: 100%;
}
.caruousel {
  height: 100%;
  position: relative;
  .item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    .item__content {
      position: absolute;
      left: 0;
      right: 0;
    }
  }
  .item1 {
    background-image: url(@/assets/images/home/BannerSwiper3.png);
  }
  .item2 {
    background-image: url(@/assets/images/home/BannerSwiper1.jpg);
  }
  .item3 {
    background-image: url(@/assets/images/home/BannerSwiper2.jpg);
  }
  .item4 {
    background-image: url(@/assets/images/home/ttkh-page-title.jpg);
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.item__title {
  font-size: 40px;
  letter-spacing: -0.01em;
  line-height: 1.05;
  margin-bottom: 20px;
  a {
    color: #fff;
  }
  a:hover {
    color: #f88126 !important;
    text-decoration: none !important;
  }
}
.item__text {
  max-width: 550px;
  font-size: 14px;
  line-height: 1.5;
  color: #fff;
  margin-bottom: 0;
}
.col-xl-9,
.col-md-5,
.col-lg-4,
.col-xl-5 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-md-9 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.slogan-ceo__blockquote {
  color: #fff;
  max-width: 810px;
}
.slogan-ceo__blockquote .f-icon {
  display: inline-block;
  margin-bottom: 20px;
}
.slogan-ceo__blockquote .f-title {
  font-size: 22px;
  line-height: 1.3;
  color: #fff;
  margin-bottom: 20px;
}
.slogan-ceo__blockquote .f-info {
  text-transform: uppercase;
  font-size: 14px;
  margin: 0;
}
.sec-coreValue {
  padding-bottom: 90px;
}
.align-items-center {
  align-items: center !important;
}
.sec-coreValue .title {
  margin-bottom: 30px;
}
.about-detail {
  padding-bottom: 70px;
}
.title__title {
  font-size: 30px;
  line-height: 1.26;
  margin-bottom: 0;
}
.title-fz-40 {
  font-size: 26px;
}
.title__text {
  padding-top: 25px;
}
.sec-coreValue .f-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sec-coreValue .f-list ul li {
  padding: 15px 0;
  border-top: 1px solid #dee2e6;
}
.sec-coreValue .f-list ul li h4 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 6px;
  margin-top: 0;
}
.sec-coreValue .f-list ul li p {
  margin-bottom: 0;
}
.sec-coreValue .f-img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 40px;
}
.sec-coreValue .f-img.wow.effect .item-1 {
  transform: translateX(-68%);
}
.sec-coreValue .f-img.wow.effect .item-2 {
  transform: translateY(-68%);
}
.sec-coreValue .f-img.wow.effect .item-3 {
  transform: translateX(68%);
}
.sec-coreValue .f-img.wow.effect .item-4 {
  transform: translateY(68%);
}
.sec-coreValue .f-img .item-center {
  max-width: 32%;
}
.sec-coreValue .f-img .item {
  position: absolute;
  max-width: 36%;
  transition: all 2s;
}
.sec-coreValue .f-img::after {
  content: "";
  display: block;
  padding-top: 100%;
}
.sec-history {
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0a54a8;
  color: #fff;
}
.sec-history .title {
  margin-bottom: 60px;
}
.sec-history .title__title {
  color: #fff;
}
.sec-history .f-history img {
  width: 100%;
}
.sec-modalAdmin {
  background-color: #f7f7f7;
}
.title-margin {
  margin-bottom: 30px;
}
.img-fullwidth {
  width: 100%;
}
.footer {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.65);
  background: linear-gradient(-90deg, #188cd3 0%, #155eb2 100%);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 45px;
  filter: grayscale(100%);
}
.footer__content {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 20px 0;
}
.footer__list {
  margin-bottom: 30px;
}

.footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer__list ul li {
  display: inline-block;
  margin-top: 0 !important;
}
.footer__list ul li::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 5px;
}
.footer a {
  color: rgba(255, 255, 255, 0.8);
}
@media (min-width: 1200px) {
  .header:not(.header-default).headroom--top .header__content:not(:hover) {
    background-color: transparent;
  }
  .header:not(.header-default).headroom--top
    .header__content:not(:hover)
    .header__inner
    p {
    color: #fff;
  }
  .header:not(.header-default) .header__fixheight {
    display: none;
  }
  .hero {
    min-height: 100vh;
  }
  .section,
  section {
    padding-top: 110px;
    padding-bottom: 110px;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .slogan-ceo__blockquote .f-title {
    font-size: 36px;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .sec-history .title {
    margin-bottom: 110px;
  }
}
@media (max-width: 1199.98px) {
  .header__fixheight,
  .header__inner {
    height: 60px;
  }
  .header__inner {
    padding-left: 17px;
    padding-right: 17px;
  }
}
@media (min-width: 992px) {
  .slogan-ceo__blockquote .f-icon {
    margin-bottom: 35px;
  }
  .slogan-ceo__blockquote .f-title {
    font-size: 30px;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .title-fz-40 {
    font-size: 40px;
  }
  .title__title {
    font-size: 50px;
    line-height: 1.14;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .sec-history .title {
    margin-bottom: 80px;
  }
  .sec-modalAdmin .title-margin {
    margin-bottom: 80px;
  }
}
@media (min-width: 768px) {
  .header__logo a {
    max-width: 250px;
  }
  .section,
  section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .item__title {
    font-size: 50px;
  }
  .item__text {
    font-size: 18px;
  }
  .slogan-ceo .bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background-color: #f88126;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .d-md-none {
    display: none !important;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .sec-coreValue .title {
    margin-bottom: 50px;
  }
  .title-fz-40 {
    font-size: 35px;
  }
  .title__title {
    font-size: 40px;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .sec-coreValue .f-img {
    margin-top: 0;
  }
  .sec-history .f-history {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .sec-history .f-history .mb {
    display: none;
  }
  .title-margin {
    margin-bottom: 40px;
  }
}
@media (max-width: 767.98px) {
  .row [class*="col-"] {
    padding-left: 8px;
    padding-right: 8px;
  }
  .slogan-ceo .bg {
    background-image: none !important;
    margin-top: -15%;
  }
  .slogan-ceo .bg img {
    width: 100%;
  }
  .slogan-ceo {
    background-color: #f88125;
    padding-bottom: 0;
  }
  .sec-history .f-history .pc {
    display: none;
  }
  .sec-history .f-history {
    padding-left: 23px;
    padding-right: 23px;
  }
  .sec-modalAdmin .f-img {
    overflow-x: auto;
    margin-left: -25px;
    margin-right: -25px;
  }
  .sec-modalAdmin .f-img img {
    width: 600px;
    max-width: inherit;
  }
  .header__inner p {
    display: none;
  }
  .header__inner a {
    font-size: 20px;
    font-weight: 600;
  }
  .item1 {
    background-image: url(@/assets/images/home/BannerMB1.png) !important;
  }
  .item2 {
    background-image: url(@/assets/images/home/BannerMB2.png) !important;
  }
  .item3 {
    background-image: url(@/assets/images/home/BannerMB3.jpg) !important;
  }
  .item4 {
    background-image: url(@/assets/images/home/BannerMB4.jpg) !important;
  }
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}
</style>

<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link>
      <router-link to="/about">About</router-link>
      <md-button class="md-raised md-primary">Default</md-button>
      <div class="a">sss</div>
    </nav> -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  watch: {
    $route() {
      if (this.$store.getters.isLogin) {
        this.$store.dispatch("getUserInfo");
      }
    },
  },

  methods: {
    setImgBg() {
      const randomImg = Math.floor(Math.random() * 5);
      const img = require(`@/assets/images/common/bg${randomImg}.jpg`);
      if (img) {
        document.documentElement.style.setProperty("--img-bg", `url(${img})`);
      }
    },
  },

  mounted() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    // Connect socket
    if (!this.$socket.getSocket) {
      this.$socket.setupSocketConnection(this.$store.state.token);
    }

    this.setImgBg();
  },
};
</script>

<style lang="scss">
body {
  overflow: auto;
  background: #242424;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
@import "vue-material/dist/theme/engine";
@include md-register-theme(
  "body-yellow",
  (
    primary: md-get-palette-color(yellow, A700),
    theme: dark,
  )
);
@import "vue-material/dist/theme/all";
</style>

import request from "@/request";
import API from "@/api";

const actions = {
  getUserInfo({ commit }) {
    request.get(API.GET_ME).then((res) => {
      commit("SET_USER_INFO", res.data);
    });
  },
};

export default actions;

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./lang/i18n";
import Toast from "vue-toastification";
import "./lang/responseMessage";
import VueMaterial from "vue-material";
import VueClipboard from "vue-clipboard2";

// import {
//   MdButton,
//   MdBottomBar,
//   MdIcon,
//   MdDialog,
//   MdRadio,
//   MdSnackbar,
//   MdToolbar,
//   MdCard,
//   MdTabs,
//   MdField,
//   MdMenu,
//   MdList,
//   MdTable,
// } from "vue-material/dist/components";

import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import "vue-toastification/dist/index.css";
import { chatSocket } from "@/sockets";

// Vue.use(MdButton);
// Vue.use(MdBottomBar);
// Vue.use(MdIcon);
// Vue.use(MdDialog);
// Vue.use(MdRadio);
// Vue.use(MdSnackbar);
// Vue.use(MdToolbar);
// Vue.use(MdCard);
// Vue.use(MdTabs);
// Vue.use(MdField);
// Vue.use(MdMenu);
// Vue.use(MdList);
// Vue.use(MdTable);

Vue.use(VueMaterial);
const toastOptions = {
  transition: "my-custom-fade",
  position: "top-center",
  timeout: 2000,
  hideProgressBar: true,
  closeButton: false,
  icon: false,
};

Vue.use(Toast, toastOptions);
Vue.use(VueClipboard);

Vue.config.productionTip = false;
Vue.prototype.$socket = chatSocket;

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");

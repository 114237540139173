import Vue from "vue";
import VueI18n from "vue-i18n";
import viLang from "./vi.json";
import enLang from "./en.json";
Vue.use(VueI18n);

const messages = {
  vi: viLang,
  en: enLang,
};

const locale = localStorage.getItem("locale");
const lang = locale ? locale : "vi";

const i18n = new VueI18n({
  locale: lang, // set locale
  messages,
  fallbackLocale: "vi",
});

export default i18n;

import Vue from "vue";
import axios from "axios";
import store from "@/store";
import router from "@/router";

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

const errorResponseHandler = (error) => {
  if (error.response.status == 401) {
    store.commit("removeToken");
    router.push({ name: "login", replace: true }).catch(console.error);
    return Vue.$toast.error(error.response.data?.error?.error || "error");
  }
  return Promise.reject(error.response);
};

const errorRequestHandler = (error) => {
  return Promise.reject(error.request);
};

// request interceptor
request.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");
  const lang = localStorage.getItem("locale");
  if (config.params && lang) {
    config.params = { ...config.params, lang };
  } else if (lang) {
    config.params = { lang };
  }
  if (token) {
    config.headers["authorization"] = "Bearer " + token;
  }
  return config;
}, errorRequestHandler);

// response interceptor
request.interceptors.response.use((response) => {
  return response.data;
}, errorResponseHandler);

export default request;

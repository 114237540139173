import { defaultState, ACCESS_TOKEN } from "./state";

const mutations = {
  setToken(state, payload) {
    state.token = payload;
    localStorage.setItem(ACCESS_TOKEN, payload);
  },

  removeToken(state) {
    state.token = null;
    localStorage.removeItem(ACCESS_TOKEN);
  },

  SET_USER_INFO(state, payload) {
    state.userInfo = payload;
  },

  UPDATE_USER_INFO(state, payload) {
    Object.assign(state.userInfo, payload);
  },

  setMoneyDeposit(state, payload) {
    state.moneyDeposit = payload;
  }
};

export default mutations;

import { io } from "socket.io-client";
import store from "@/store";
import router from "@/router";

class SocketiIOService {
  #socket;
  #url = "";
  #path = "";
  constructor(url, path = "") {
    this.#url = url;
    this.#path = path;
    let token = store.getters.isLogin;
    if (token) {
      this.setupSocketConnection(token);
    }
  }

  get getSocket() {
    return this.#socket;
  }

  setupSocketConnection(token) {
    this.#socket = io(this.#url, {
      autoConnect: true,
      withCredentials: false,
      secure: true,
      auth: {
        token: `koh ${token}`,
      },
      path: this.#path,
      transports: ["websocket"],
    });
  }

  disconnect() {
    if (this.#socket) {
      this.#socket.disconnect();
    }
  }

  listenConnectedMessage() {
    this.#socket.on("error", function () {
      console.log("Connection Failed");
      store.commit("removeToken");
      router.push({ name: "login", replace: true });
    });
  }
}

export default SocketiIOService;

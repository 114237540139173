const ACCESS_TOKEN = "accessToken";

const defaultState = () => {
  return {
    token: localStorage.getItem(ACCESS_TOKEN)
      ? localStorage.getItem(ACCESS_TOKEN)
      : "",
    langCode: {},
    userInfo: null,
    moneyDeposit: 0,
  };
};

const state = defaultState();

export default state;
export { defaultState, ACCESS_TOKEN };

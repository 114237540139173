import request from "@/request";
import Vue from "vue";

const RESPONSE_KEY = "response";

async function getResponseCode() {
  try {
    const response = JSON.parse(localStorage.getItem(RESPONSE_KEY));
    if (!response) {
      const { data } = await request.get("/api/responsive/code");
      if (data) {
        localStorage.setItem(RESPONSE_KEY, JSON.stringify(data));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function responseMessage(key) {
  try {
    const response = JSON.parse(localStorage.getItem(RESPONSE_KEY));
    if (response[key]) {
      return response[key];
    } else {
      throw new Error("key not found");
    }
  } catch (error) {
    return key;
  }
}

function responseErrorMessage(error) {
  const message = this.$response(error.data?.error?.message);
  this.$toast.error(message || "error");
}

Vue.prototype.$response = responseMessage;
Vue.prototype.$errorResponse = responseErrorMessage;

getResponseCode();

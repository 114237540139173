export default {
  // user
  WITHDRAW: "/api/users/withdraw",
  DEPOSIT: "/api/users/deposit",
  GET_ME: "api/users/me",
  BANK_CARD: "api/users/bank-card",

  // order
  ORDER_LIST: "api/order/personal-assets",
  ORDER_PROFIT: "api/order/profit",

  // vip
  VIP_LIST: "api/vip/list",

  // deposit
  DEPOSIT_LIST: "/api/deposit/list",

  LOGOUT: "/api/users/logout",

  // historyDeposit
  HISTORY_DEPOSIT_CREATE: "/api/historyDeposit/create",
  HISTORY_DEPOSIT_LIST_START: "/api/historyDeposit/listByUser?status=1",
  HISTORY_DEPOSIT_LIST_END: "/api/historyDeposit/listByUser?status=0",
  HISTORY_DEPOSIT_CHECK_DEPOSIT: "/api/historyDeposit/checkDeposit",

  // config
  GET_CONFIG: "api/admin/config"
};
